<template>
  <div class="ManageMissionTypes">

    <!-- MODIFY MISSION TYPE -->
    <CModal
      :show.sync="isMissionTypeModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre type de mission"
      size="lg"
      color="dark"
    >
      <b-form v-on:submit.prevent="modifyMissionType()">

        <CInput
            label="Nom de votre type de mission"
            v-model="modifyMissionTypeName" type="text"
            @input="$v.modifyMissionTypeName.$touch()"
            :isValid="$v.modifyMissionTypeName.$dirty ? !$v.modifyMissionTypeName.$error : null"
          >
        </CInput>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyMissionTypeButtonStyle"
              type="submit"
              shape="pill" block class="px-4"
              :disabled="$v.modifyMissionTypeName.$invalid || modifyMissionTypeInProcess">
              <CSpinner size="sm" label="update mission type spinner" v-if="modifyMissionTypeInProcess"></CSpinner>
              {{ modifyMissionTypeButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </b-form>

      <template #footer>
        <CButton @click="isMissionTypeModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1> Types de missions </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>

        <CRow>
          <CCol>
            <strong> Ajouter un nouveau type de mission </strong>
            <CDataTable
              addTableClasses="no-border-table"
              :header="false"
              headVariant="light"
              :items="[newMissionType]"
              :fields="newMissionTypeRenderFields"
            >

            <template #name="">
              <td>
                <CInput
                  placeholder="Nom du type de mission"
                  type="text"
                  v-model="newMissionType.name"
                  @input="$v.newMissionType.name.$touch()"
                  :isValid="$v.newMissionType.name.$dirty ? !$v.newMissionType.name.$error : null">
                </CInput>
              </td>
            </template>
            <template #actions="">
              <td>
                <CButton
                  :color="createMissionTypeButtonStyle"
                  square
                  :disabled="$v.newMissionType.$invalid || createMissionTypeInProcess"
                  @click="createMissionType(newMissionType.name)"
                >
                    {{createMissionTypeButtonText}}
                </CButton>

              </td>
            </template>
            </CDataTable>
          </CCol>
        </CRow>

        <CDataTable
          :items="missionTypesRender"
          :fields="missionTypesRenderFields"
        >
        <template #actions="{item}">
          <td class="text-center">
            <CButton
              class="ml-2"
              square
              @click="showModifyMissionType(item)">
              <CIcon class="text-primary" name="cil-pencil"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              :disabled="deleteMissionTypeInProcess"
              @click="deleteMissionType(item.id)">
              <CIcon class="text-danger" name="cil-trash"/>
            </CButton>

          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun type de mission enregistré
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <strong>Vos types de missions inactifs</strong>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="inactiveMissionTypesRender"
          :fields="inactiveMissionTypesRenderFields"
        >
        <template #actions="{item}">
          <td class="text-center">
            <CButton
              :color="activateMissionTypeButtonStyle"
              square
              size="sm"
              :disabled="activateMissionTypeInProcess"
              @click="activateMissionType(item.id)"
            >
                {{activateMissionTypeButtonText}}
            </CButton>
          </td>
        </template>
        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun type de mission inactif
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>

import { APIAdminConnected } from '@/api/APIAdminConnected'
import { APIUserConnected } from '@/api/APIUserConnected'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiAdminConnected = new APIAdminConnected()
const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageMissionTypes',
  components: {
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      isMissionTypesLoading: false,
      missionTypes: [],
      missionTypesRender: [],
      missionTypesRenderFields: [
        { key: "name", label: "Nom", sortable: true, _classes: "td-vertical-align"},
        { key: "actions", label: "", _style: "text-align: center;"},
      ],

      // ----------- INACTIVE MISSION TYPE -------
      isInactiveMissionTypesLoading: false,
      inactiveMissionTypes: [],
      inactiveMissionTypesRender: [],
      inactiveMissionTypesRenderFields: [
        { key: "name", label: "Nom", sortable: true},
        { key: "actions", label: "", _style: "text-align: center;"},
      ],


      // ------------ CREATE MISSION TYPE --------
      newMissionTypeRenderFields: [
        { key: "name", label: "Nom"},
        { key: "actions", label: "Actions"},
      ],
      newMissionType: {
        'name': '',
      },
      createMissionTypeButtonText: 'Créer un nouveau type de mission',
      createMissionTypeButtonStyle: 'primary',
      createMissionTypeInProcess: false,

      // ---------- MODIFY MISSION TYPE ---
      isMissionTypeModifying: false,

      modifyMissionTypeId: 0,
      modifyMissionTypeName: '',

      modifyMissionTypeButtonText: 'Modifier',
      modifyMissionTypeButtonStyle: 'outline-primary',
      modifyMissionTypeInProcess: false,

      // ---------- DELETE MISSION TYPE ---
      deleteMissionTypeButtonText: 'Rendre inactif',
      deleteMissionTypeButtonStyle: 'outline-danger',
      deleteMissionTypeInProcess: false,

      // ---------- DELETE MISSION TYPE ---
      activateMissionTypeButtonText: 'Rendre actif',
      activateMissionTypeButtonStyle: 'outline-primary',
      activateMissionTypeInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionTypesLoading || this.isInactiveMissionTypesLoading) {
        return true
      }
      return false
    },
  },
  validations: {
    newMissionType: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(150)
      },
    },
    modifyMissionTypeName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
  },
  created: function() {
    this.getAllMissionTypes()
    this.getAllInactiveMissionTypes()
  },
  watch: {
    missionTypes: function (newMissionTypes) {
      if (newMissionTypes.length == 0) {
        this.missionTypesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissionTypes.length; i++) {
          final_array.push(
            {
              'id': newMissionTypes[i].id,
              'name': newMissionTypes[i].name,
            }
          )
        }
        this.missionTypesRender = final_array
      }
    },
    inactiveMissionTypes: function (newInactiveMissionTypes) {
      if (newInactiveMissionTypes.length == 0) {
        this.inactiveMissionTypesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newInactiveMissionTypes.length; i++) {
          final_array.push(
            {
              'id': newInactiveMissionTypes[i].id,
              'name': newInactiveMissionTypes[i].name,
            }
          )
        }
        this.inactiveMissionTypesRender = final_array
      }
    },
  },
  methods: {
    // GETTERS
    getAllMissionTypes () {
      this.isMissionTypesLoading = true
      apiUserConnected.getAllMissionTypes(this.token)
      .then((result) => {
        this.missionTypes = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypesLoading = false
      })
    },

    getAllInactiveMissionTypes () {
      this.isInactiveMissionTypesLoading = true
      apiAdminConnected.getAllInactiveMissionTypes(this.token)
      .then((result) => {
        this.inactiveMissionTypes = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isInactiveMissionTypesLoading = false
      })
    },

    // CREATE MISSION_TYPE
    createMissionType () {
      this.createMissionTypeInProcess = true
      this.createMissionTypeButtonText = "Ajout en cours"
      this.createMissionTypeButtonStyle = 'secondary'
      apiAdminConnected.createMissionType(this.token, this.newMissionType.name)
      .then(() => {
        this.getAllMissionTypes()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.createMissionTypeInProcess = false
        this.createMissionTypeButtonText = "Créer un nouveau type de mission"
        this.createMissionTypeButtonStyle = 'primary'
        this.newMissionType.name = ''
      })
    },

    // ---------- MODIFY MISSION TYPE ------------
    showModifyMissionType(item) {
      this.modifyMissionTypeId = item.id
      this.modifyMissionTypeName = item.name
      this.isMissionTypeModifying = true
    },
    modifyMissionType () {
      this.modifyMissionTypeButtonText = 'Modification en cours'
      this.modifyMissionTypeButtonStyle = 'secondary'
      this.modifyMissionTypeInProcess = true
      apiAdminConnected.modifyMissionType(this.token,
        this.modifyMissionTypeId, this.modifyMissionTypeName)
      .then(() => {
        this.getAllMissionTypes()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypeModifying = false
        this.modifyMissionTypeButtonText = 'Modifier'
        this.modifyMissionTypeButtonStyle = 'outline-primary'
        this.modifyMissionTypeInProcess = false
      })
    },

    // ------- DELETE MISSION TYPE ------------
    deleteMissionType (mission_type_id) {
      this.deleteMissionTypeButtonText = 'Désactivation en cours'
      this.deleteMissionTypeButtonStyle = 'secondary'
      this.deleteMissionTypeInProcess = true
      apiAdminConnected.deleteMissionType(this.token, mission_type_id)
      .then(() => {
        this.getAllMissionTypes()
        this.getAllInactiveMissionTypes()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypeModifying = false
        this.deleteMissionTypeButtonText = 'Rendre inactif'
        this.deleteMissionTypeButtonStyle = 'outline-danger'
        this.deleteMissionTypeInProcess = false
      })
    },

    // ------- DELETE MISSION TYPE ------------
    activateMissionType (mission_type_id) {
      this.activateMissionTypeButtonText = 'Activation en cours'
      this.activateMissionTypeButtonStyle = 'secondary'
      this.activateMissionTypeInProcess = true
      apiAdminConnected.activateMissionType(this.token, mission_type_id)
      .then(() => {
        this.getAllMissionTypes()
        this.getAllInactiveMissionTypes()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypeModifying = false
        this.activateMissionTypeButtonText = 'Rendre actif'
        this.activateMissionTypeButtonStyle = 'outline-primary'
        this.activateMissionTypeInProcess = false
      })
    },
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}

.table thead th {
  vertical-align: middle !important;
}

.td-vertical-align {
  vertical-align: middle !important;
}

.cursor-table tr {
  cursor: pointer;
}
</style>
